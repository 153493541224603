<template>
  <div class="flex items-center justify-center min-h-screen">
    <img :src="logo" alt="Logo" class="w-40 h-auto">
  </div>

</template>

<script>
import logo from './assets/fndtn2.png';
export default {
  name: 'App',
  data() {
    return {
      logo
    };
  }
}
</script>

<style>

</style>
